import React from "react"
import { ImageNoGenerations } from "../Icons/ImageNoGenerations"
import { Flex } from "antd"

export const Empty: React.FC = ()=>{

    return <Flex vertical style={{width: '100%', display: 'flex', alignItems: 'center', gap: '24px' }}> 
    <ImageNoGenerations />
    <span style={{
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21.94px',
        textAlign: 'center',
        }}>Нет генераций</span>
    </Flex>
}