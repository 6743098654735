import { io, Socket } from 'socket.io-client'
import { RootState } from './store'
import { apiSlice } from '../api/apiSlice'
import { showNotification } from './notificationSlice'
import { Mutex } from 'async-mutex'

export const socketMiddleware =
  (socket: Socket) => (params: any) => (next: any) => async (action: any) => {
    const { dispatch, getState } = params
    const { type } = action
      console.log(socket.listeners('message'))
      switch (type) {
        case 'socket/connect':
          
          if(!socket.hasListeners('message')){
            console.log('WS socketMiddleware connect')
            socket.on('open', () => {
              console.log('ws open')
            })

            socket.on('message', data => {
              console.log('ws message ', data)
              if (data.action) {
                switch (data.action) {
                  case 'errorMessage':
                    dispatch(
                      showNotification({
                        type: 'error',
                        description: data.message,
                        message: 'Ошибка!'
                      })
                    )
                    break
                  case 'messageUpdated':
                    dispatch(apiSlice.util.invalidateTags(['ChatMessages']))
                    break
                  case 'chatUpdated':
                    dispatch(apiSlice.util.invalidateTags(['Chats']))
                    break
                }
              }
            })
            socket.on('close', () => {
              socket.offAny()
              
              console.log('ws closed')
              
            })
          }
          const token = (getState() as RootState).auth.token
          socket.auth = {
            Authorization: `Bearer ${token}`
          }
          socket.connect()
          break
  
        case 'socket/disconnect':
          console.log('WS Disconnect')
          socket.disconnect()
          
          break
  
        default:
          break
      }


    return next(action)
  }
