import React, { useEffect, useState } from "react";
import { ImageViewer } from "../common/ImageViewer";
import { useDeleteChatMutation, useToggleFavoriteMutation } from "../../store/webchatApiSlice";
import { LoadingOutlined, QuestionCircleOutlined, XFilled } from "@ant-design/icons";
import { IconStarSelected } from "../Icons/IconStarSelected";
import { IconStar } from "../Icons/IconStar";
import { IconLinearEye } from "../Icons/IconLinearEye";
import { IconCross } from "../Icons/IconCross";

import './ChatAudioCard.scss'
import { Popconfirm } from "antd";
import { IconDownload } from "../Icons/IconDownload";
import { IconAudio } from "../Icons/IconAudio";
import { SoundPlayer } from "../SoundPlayer/SoundPlayer";
import { useStore } from "react-redux";
import { getPlayState, setPlayState, setPlayStop } from "../../store/audioPlayerSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { IconVolumeHigh } from "../Icons/IconVolumeHigh";

type PropsType = {
    item: any,
    mode: string
}
export const ChatAudioCard: React.FC<PropsType> =({item, mode})=>{
    const [ toggleFavoriteLoading, setTogglingFavoriteLoading ] = useState<boolean>(false)
    const [ deleteChatMutation ] = useDeleteChatMutation()
    const [ toggleFavoriteMutation ] = useToggleFavoriteMutation()
    const [ openFull, setOpenFull ] = useState(null)
    const dispatch = useAppDispatch()
    // const [ playerState, setPlayerState ] = useState<string>('idle')
  
    const playerState = useAppSelector((state)=>getPlayState(state, item.id))

    const downloadImage = (url: any)=>()=>{
        window.location.assign(url)
      }
      const deleteChat = ()=>{
        // console.log(item)
        deleteChatMutation({chat_id: item.id})
      }
      const openImagePreview = (item: any)=>()=>{
        if(playerState==='idle') {
          // setPlayerState('play')
          dispatch(setPlayState(item.id))
        } else {
          dispatch(setPlayStop(null))
        }
      }

      const stopPlayer = ()=>{
        dispatch(setPlayStop(null))
      }

      const toggleFavorite = (item: any)=>()=>{
        setTogglingFavoriteLoading(true)
        toggleFavoriteMutation({chat_id: item.id}).finally(()=>{
          setTogglingFavoriteLoading(false)
        })
      }
    
    return <div key={item.id} className="chatAudioCard">
    {/* <div className="chatAudioCardImage">
        { item.state==='loading' && <div className="imageLoader"><LoadingOutlined style={{ fontSize: '64px', color: '#08c' }} /></div>}
        { item.state==='idle' && <div>
          {item.text}
        </div>
        }
    </div> */}
    <div className="chatAudioCardTitle">
      <IconAudio /> 
      <span>
        File_{item.id}
      </span>
      </div>
      { item.webchat_result_audio &&
        <SoundPlayer url={item.webchat_result_audio.url} state={playerState} onStop={stopPlayer} />
      }
    <div className="chatAudioCardIcons">
      { mode ==='user' && <div className="chatAudioCardIconBtn" onClick={toggleFavorite(item)}>
      { toggleFavoriteLoading? <LoadingOutlined /> :
      (item.is_favorite? <IconStarSelected /> : <IconStar />)}
      </div>
      }
      { mode ==='user' &&  <div className="chatAudioCardIconBtnGap"></div> }
      <div className={"chatAudioCardIconBtn "+(item.webchat_result_audio?"hasAudio":'')} onClick={downloadImage(item.webchat_result_audio?.url)}><IconDownload /></div>
      <div className="chatAudioCardIconBtnGap"></div>
      
      <div className="chatAudioCardIconBtn" onClick={openImagePreview(item)}>
        { playerState==='idle' && item.webchat_result_audio && <IconVolumeHigh />}
        { playerState==='play' && <XFilled />}
      </div>
      <div className="chatAudioCardIconBtnGap"></div>
      <div className="chatAudioCardIconBtn">
          <Popconfirm style={{width: '200px'}}
                title="Удалить чат? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
        </div>
    </div>
  </div>
}