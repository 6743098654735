import { Button, Flex, Form } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import React, { createRef, useState } from "react";
import { AudioSynthesisHistoryList } from "./AudioSynthesisHistoryList";

import "./AudioSynthesis.scss"
import { AudioSynthesisProperties } from "./AudioSynthesisProperties";
import { useSendTextToSpeechMutation } from "../../../store/webchatApiSlice";
import { useAppDispatch } from "../../../store/store";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";

export const AudioSynthesis: React.FC = () => {
  const dispatch = useAppDispatch()
  const textRef = createRef<HTMLTextAreaElement>(); // like this
  const [extprops, setExtProps] = useState({
    language: "ru-ru",
    voice: "marina",
    role: "neutral",
    speed: 1,
    pitch: 0
  });

  const [send_text, setSendText] = useState<string>("");
  const [sendTextToSpeech] = useSendTextToSpeechMutation()
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const send = () => {
    sendTextToSpeech({send_text, voice: extprops.voice, role: extprops.role, speed: extprops.speed, pitch: extprops.pitch}).unwrap().catch(error=>{
      const description = errorDecode(error)

      dispatch(showNotification({type: 'error',
        description,
        message: 'Ошибка!'}))
    })
  };


  return (
    <Flex style={{ width: "100%" }} vertical className="audioSynthesis">
      <div className="sendMessageContainer" style={{ height: "auto" }}>
        <div className="sendMessageForm">
          <Form style={{ width: "100%" }}>
            <FormItem
              {...formItemLayout}
              style={{ marginBottom: "12px", width: "100%" }}>
              <TextArea
                style={{
                  border: "none",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
                ref={textRef}
                rows={10}
                
                value={send_text}
                onChange={(val) => setSendText(val.target.value)}
                placeholder="Введите текст"
              />
            </FormItem>
          </Form>
        </div>
        <div className="sendMessageBtn">
          <Button type="primary" size="large" onClick={send}>
            Синтезировать речь
          </Button>
        </div>
      </div>
      <Flex vertical className="audioSynthesisHint">
        <span>
          Для передачи слов-омографов, используйте «+» перед ударной гласной:
          з+амок, зам+ок.
        </span>
        <span>
          Чтобы отметить паузу между словами используйте «-». Ограничение
          на длину строки: 5000 символов.
        </span>
      </Flex>
      <Flex>
        <AudioSynthesisProperties extprops={extprops} setExtProps={setExtProps}/>
      </Flex>
      <Flex>
        <AudioSynthesisHistoryList />
      </Flex>
    </Flex>
  );
};
