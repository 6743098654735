import { Button, Flex, Popconfirm, Table } from "antd";
import React, { useState } from "react";
import { useDeleteChatMutation, useGetTextPreviewQuery, useToggleFavoriteMutation } from "../../../store/webchatApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { IconStar } from "../../../Components/Icons/IconStar";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./LastTextGenerations.scss";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const LastTextGenerations: React.FC = () => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const navigate = useNavigate()

  const [ deleteChatMutation ] = useDeleteChatMutation()
  const [ toggleFavoriteMutation ] = useToggleFavoriteMutation()
  const [ tableSorter, setSorter] = useState<any>({field: "created_at", order: "descend" });

  const { data, isLoading } = useGetTextPreviewQuery({
    page: 1,
    limit: 5,
    output_type: "text",
    order: tableSorter.field,
    order_dir: tableSorter.order
  });

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: true
    },
    {
      title: "Запрос",
      dataIndex: "text",
      key: "text",
      render: (text: string, record: any) => {
        return record.name
      }
    },
    {
      title: "Тип задачи",
      dataIndex: "neuronet",
      key: "neuronet",
      render: (text: string, record: any) => {
        if (record.option) {
          return (
            <div className="lastTextGenerationsTableNeuronet">
              {record.option.image && (
                <img src={record.option.image.url} width="18px" alt="" />
              )}
              {record.option.name}
            </div>
          );
        } else if (record.neuronet) {
          return (
            <div className="lastTextGenerationsTableNeuronet">
              {record.neuronet.image && (
                <img src={record.neuronet.image.url} width="18px" alt="" />
              )}
              {record.neuronet.name}
            </div>
          );
        }
      },
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (text: string, record: any) => {
        const created_at = new Date(record.created_at).toLocaleDateString();
        return created_at;
      },
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text: string, record: any) => {
        return (
          <div className="lastTextGenerationsTableOperations">
            <span onClick={toggleFavorite(record)}>
              {record.is_favorite? <IconStarSelected /> : <IconStar />}
            </span>
            <span>
            <Popconfirm style={{width: '200px'}}
                title="Удалить чат? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat(record)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
            </span>
          </div>
        );
      },
    },
  ];

  const toggleFavorite = (item: any)=>()=>{
    toggleFavoriteMutation({chat_id: item.id})
  }

  const deleteChat = (item: any) => () => {
    deleteChatMutation({chat_id: item.id})
  }

  const changedTable = (pagination: any, filters: any, sorter: any, extra: any) => {
    if(sorter.field){
      setSorter({...tableSorter, field: sorter.field, order: sorter.order})

    }
  };

  return (
    <div className="lastTextGenerations">
      <div className="lastTextGenerationsHeader">
        <Flex justify="space-between">
          <span className="lastTextGenerationsHeaderTitle">Последние генерации</span>
          <span>
            <Button onClick={()=>{navigate('/lk/history')}}>Смотреть все генерации</Button>
          </span>
        </Flex>
      </div>

        <Table
          bordered={false}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          onChange={changedTable}
          pagination={false} 
          className={
            "lastTextGenerationsTable " +
            (currentAlgorithm === "dark" ? "table_dark" : "")
          }
          rowKey={(record) => record.id}></Table>

    </div>
  );
};
