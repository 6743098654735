import { Button, Flex, Tabs } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SoundRecorder } from "../../../Components/SoundRecorder/SoundRecorder";
import { AudioSynthesis } from "./AudioSynthesis";
import { AudioRecognition } from "./AudioRecognition";
import { AudioRecognitionHistoryList } from "./AudioRecognitionHistoryList";
import { AudioRecognitionFile } from "./AudioRecognitionFile";
import { IconMicrophone } from "../../../Components/Icons/IconMicrophone";
import { IconDownload } from "../../../Components/Icons/IconDownload";



export const LkAudioChatList: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const activeTab = location.pathname
  const [recognitionSource, setRecognitionSource] = React.useState<string>("mic");

  return <Flex vertical style={{width:"100%"}}>
    <Flex style={{width:"100%", justifyContent: 'space-between'}}>
      <Flex>
        <Tabs 
        defaultValue={''}
        activeKey={activeTab}
        onChange={navigate}
        items={[
          {
            key: '/lk/audio/synthesis',
            label: 'Синтез речи',
          },
          {
            key: '/lk/audio/recognition',
            label: 'Распознавание речи',
          },
        ]}> 
          <Tabs.TabPane>Синтез речи</Tabs.TabPane>
        </Tabs>
      </Flex>
      <Flex style={{gap: '8px'}}>
      {activeTab==='/lk/audio/recognition' && <>
        <Button onClick={()=>setRecognitionSource('mic')} 
        type={recognitionSource==='mic'?'primary':'default'}
        icon={<IconMicrophone className={recognitionSource==='mic'?'primary':''} />}
        >
          
          Запись голоса
        </Button>
        <Button onClick={()=>setRecognitionSource('file')} type={recognitionSource==='file'?'primary':'default'}
          icon={<IconDownload className={recognitionSource==='file'?'primary':''}/>}>
          
          Загрузка файла
        </Button>
        </>
      }
      </Flex>
    </Flex>
    <Flex style={{width:"100%", gap: '24px'}} vertical>
      {activeTab==='/lk/audio/synthesis' &&
        <AudioSynthesis />
      }
      {activeTab==='/lk/audio/recognition' && <>
        { recognitionSource === 'mic' && 
          <AudioRecognition />
        }
        { recognitionSource === 'file' && 
          <AudioRecognitionFile />
        }
        <AudioRecognitionHistoryList />  
      </>
      }
      
      
    </Flex>
  </Flex>
}